import React, {useEffect} from "react"
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage } from '../../../redux/slices/oomnieDevFlowSlice.js';

const Navbar = ({}) => {

const dispatch = useDispatch();
const mode = useSelector(state => state.environment.mode)
const currentPage = useSelector(state => state.oomnieDevFlow.currentPage)

useEffect(()=>{
  console.log(currentPage)
},[currentPage])

  return (
      <div className={`flex h-[50px] w-full items-center text-[16px]]
          header-strip-mode-${mode}
          transition duration-500 mb-3` }>

          <div 
            className={
              `${currentPage === "summary" ?"navbar-item-selected-mode-"+mode : "navbar-item-mode-"+mode}
              w-fit p-2 m-2 cursor-pointer text-center`}
            onClick = {(e)=>dispatch(setCurrentPage("summary"))}
          >Summary</div>

          <div 
            className={
              `${currentPage === "predictive_analytics" ?"navbar-item-selected-mode-"+mode : "navbar-item-mode-"+mode}
              w-fit p-2 m-2 cursor-pointer text-center`}
            onClick = {(e)=>dispatch(setCurrentPage("predictive_analytics"))}
          >Predictive Analysis</div>
      </div>
  )
}

export default Navbar
