import React, { useState } from 'react';
import {useSelector} from 'react-redux'
import MultiInput from '../../components/MultiInput'

const App = () => {
  
  const [streamedData, setStreamedData] = useState('');
  const mode = useSelector(state=>state.environment.mode)

  const [userInput, setUserInput] = useState("")
  const [LLM, setLLM] = useState("oomniellm")

  const fetchStreamedData = async () => {
    setStreamedData(''); // Clear previous data

    const url = LLM ==="oomniellm" ? 'http://localhost:8001/oomniellm' : 'http://localhost:8001/openai/chatgpt'

    try {

      const response = await fetch(url, {
        method: 'POST', // Use POST method
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_input: userInput }),
      });

      const reader = response.body.getReader(); // Access the ReadableStream
      const decoder = new TextDecoder('utf-8'); // Decode chunks as text

      while (true) {
        const { done, value } = await reader.read(); // Read chunks
        if (done) break; // Exit loop when streaming is complete
        setStreamedData((prev) => prev + decoder.decode(value)); // Append streamed data
      }
    } catch (error) {
      console.error('Error fetching streamed data:', error);
    }

  }

  return (
    <div className={`flex flex-col overflow-y-auto`}>
      <h1 className={`w-full title-mode-${mode} text-center`}>Streaming Test</h1>
      <div className={`flex flex-col w-[400px] p-3 panel-mode-${mode} m-auto`}>

        <MultiInput 
          id="user_input"
          name = "user_input"
          label = "Question"
          value = {userInput}
          onChange = {(e)=>setUserInput(e.target.value)}
        />

        <MultiInput 
          id="llm"
          name = "llm"
          label = "Select LLM"
          value = {LLM}
          onChange = {(e)=>setLLM(e.target.value)}
          list={["oomniellm", "chatGPT"]}
        />
    

        <div className={`m-auto mt-3`}>
          <button className={`button-mode-${mode} mt-3`} onClick={fetchStreamedData}>Fetch</button>
        </div>
      </div>
      {streamedData.length>0 && <div className={`flex w-[80%] m-auto border-mode-${mode} p-5 mt-5 rounded-md`}>{streamedData}</div>}
    </div>
  );
};

export default App;

