import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage } from '../../redux/slices/oomnieWorkflowGenieSlice';


const Navbar = ({}) => {

const dispatch = useDispatch();
const mode = useSelector(state => state.environment.mode);
const currentPage = useSelector(state=>state.oomnieWorkflowGenie.currentPage)

  return (
         <div className={`flex h-[50px] w-full items-center text-[16px]]
          header-strip-mode-${mode} primary-color-${mode}
          transition duration-500 mb-3` }>

          <div 
            className={
              `${currentPage === "generate" ?"navbar-item-selected-mode-"+mode : "navbar-item-mode-"+mode}
              w-fit p-2 m-2 cursor-pointer text-center`}
            onClick = {(e)=>dispatch(setCurrentPage("generate"))}
          >
            Generate
          </div>

          <div 
          className={
            `${currentPage === "workflows" ?"navbar-item-selected-mode-"+mode : "navbar-item-mode-"+mode}
            w-fit p-2 m-2 cursor-pointer text-center`}
            onClick = {(e)=>dispatch(setCurrentPage("workflows"))}
          >
            Workflows
          </div>

        </div>
  )
}

export default Navbar
