import React, { useState, useRef, useEffect } from 'react';
import * as oomnielabsApi from '../../apis/oomnielabs'
import * as formatValue from '../../functions/formatValue.js'
import { useSelector } from 'react-redux';
import Workflow from './Workflow'
import workflows_data from './data/workflows.js'

const Generate = () => {

  const mode = useSelector(state => state.environment.mode);
  const [formData, setFormData] = useState({})
  const [showWorkflow, setShowWorkflow] = useState(false)
  const [workflowData, setWorkflowData] = useState(null)

  const handleChange = (e)=>{
    const {name, value} = e.target
    setFormData({...formData,...{[name]:value}})
  }


  const handlePresetWorkflow = async (e)=>{

    await handleReset()
    setFormData({...formData,...{"prompt": formatValue.toProperCase(`Generate ${e.target.innerHTML} workflow`)}})
  }

  const handleSubmit = async () =>{

    console.log("running generation")
    console.log(formData)
    console.log(formData.prompt)

    setShowWorkflow(false)

    const app_name = "workflow_generator/src/find_most_similar_workflow"
    const main_function = "run_similar_workflow"
    const parameters = formData.prompt

    const substrings = ["employee onboarding workflow", "default", "standard"];
    const containsSubstring = substrings.some(substring => parameters.toLowerCase().includes(substring));
  
    let response
    if (containsSubstring || parameters ==="employee onboarding workflow"){
      
      response = workflows_data.find(i=>i.name==="workflow_1")
      console.log(response)

      setWorkflowData(response)
      setShowWorkflow(true)

    }
    
    else{

      console.log(app_name)
      console.log(main_function)
      console.log(parameters)

      response = await oomnielabsApi.pythonApp(app_name,main_function,parameters)
      console.log(response)
      
      console.log(response)
      setWorkflowData(response)
      setShowWorkflow(true)

    }
    
   
  }
  
  const handleReset = async () =>{
    setFormData({prompt:""})
    setShowWorkflow(false)
  }

  
  return (
    <div className={`flex flex-col w-full h-[100%] items-center`}>

    <div className={`flex flex-col w-full h-auto panel-mode-${mode} rounded-md justify-between items-center p-3`}>
      <div className={`flex w-full h-auto justify-between items-center`}>
          <input
            id="prompt" 
            name="prompt"
            label="Desribe the document you'd like to generate"
            value = {formData.prompt}
            onChange = {(e)=>handleChange(e)}
            placeholder = "Describe the workflow you'd like to generate"
            className={`flex w-full h-[50px] outline-none bg-mode-${mode} p-3 color-[rgb(0,150,225)] rounded-lg text-[rgb(0,150,225)]`}
            autoComplete="off" 
          />

          <div className="flex ms-3 p-1 items-end justify-end">

            <div className="flex">
              <button
                  className={`button-mode-${mode}`}
                  onClick = {()=>handleSubmit()}
              >Generate</button>
            </div>

            <div className="flex">
              <button
                  className={`button-mode-secondary-${mode} ms-2`}
                  onClick = {()=>handleReset()}
              >
                Reset
              </button>
            </div>

          </div>

      </div>
    <div className={`flex w-full flex-col`}>
      <div className={`mt-3 ms-3 secondary-color-mode-${mode}`}>Common Workflows</div>
      <div className={`flex w-full `}>
        <div id="employee_onboarding_button" className={`flex button-mode-secondary-${mode} text-[12px] m-3 p-2`} onClick={(e)=>handlePresetWorkflow(e)}>Employee Onboarding</div>
        <div id="employee_offboarding_button" className={`flex button-mode-secondary-${mode} text-[12px] m-3 p-2`} onClick={(e)=>handlePresetWorkflow(e)}>Employee Offboarding</div>
        <div id="employee_server_provisioning_button" className={`flex button-mode-secondary-${mode} text-[12px] m-3 p-2`} onClick={(e)=>handlePresetWorkflow(e)}>Server Provisioning</div>
      </div>
    </div>
    </div>

    {workflowData && showWorkflow && 
      <div className={`flex w-full h-[600px] overflow-y-auto mt-5`}>
        <Workflow workflowData = {workflowData}/>
      </div>
    }

    </div>
  );
};

export default Generate;

