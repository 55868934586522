import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import MultiSeriesBarChart from './MultiSeriesBarChart';
import DonutChart from './DoughnutChart';
import Table from '../../../components/Table'
import * as oomnielabsApi from '../../../apis/oomnielabs'

import * as flowData from '../data/data'

const Summary = ({contextApi}) => {

  const mode = useSelector(state => state.environment.mode);

  const [data, setData] = useState([])
  const [selectedRecords, setSelectedRecords] = useState([])
  const [selectedDocument, setSelectedDocument] = useState(null)

  const getData = async()=>{
    const query = `SELECT * FROM features;`
    const response = await oomnielabsApi.getPythonData(query)
    setData(response)
  }

  useEffect(()=>{
    getData()
  },[])

  const handleRecordSelect = async (record)=>{
   
  }

  const handleCheck = (records)=>{

  }



  const hiddenColumns = ["id", "record_created"]



  return (
    <div className={`flex h-screen w-full fade-in justify-center overflow-y-auto flex-wrap ${mode==="light" && "bg-[rgb(245,245,245)]"}`}>
      
     <div className={`flex flex-col w-full md:w-[500px] h-[400px] overflow-hidden ${mode==="light"?"bg-white" : "panel-mode-"+mode} rounded-md shadow-md m-1 p-2 transtion duration-500`}>
      <div className={`flex flex-col w-full header-mode-${mode}`}>
          <h4>Flow Load</h4>
          <div className={`flex flex-col w-full text-[10px] max-h-[50px] overflow-y-auto rounded-lg shadow-lg border-mode-${mode} p-2 mt-2`}>
            {`There is too much work currently in progress. A high "Work In Progress" (WIP) level leads to potential delays, reduced throughput, and potential quality issues. 
            This trend indicates that the system is nearing an optimal load to maximize througput. Continue to calibrate the Work In Progress 
            by setting WIP limits at each stage of the process, ensuring that only a manageable amount of work is actively being worked on 
            at any given time.`}
          </div>
        </div>
        <div className={`flex w-full h-[80%] pt-3 fade-in`}>
          <MultiSeriesBarChart
            label = "Month"
            labels = {flowData.flowLoad.map(item => item["labels"])|| []}
            datasets = {[
              {
                label: 'Number of Features',
                values: flowData.flowLoad.map(item => item["values"]) || [],
                backgroundColor: 'rgba(0, 100, 225, 0.3)',
              },
              {
                label: 'Trend',
                values: flowData.flowLoad.map(item => item["trend"]) || [],
                type: 'line',
                borderColor: 'rgba(0, 100, 225, 1)',
                backgroundColor: 'rgba(0, 100, 225, 1)',
                pointBackgroundColor: 'rgba(0, 100, 225, 1)',
              }    
            ]}
            xAxisTitle = ""
            yAxisTitle = "Number of Features"
            mode = {mode}
            showLegend = {true}
          />
        </div>
     </div>

     <div className={`flex flex-col w-full md:w-[500px] h-[400px] overflow-hidden ${mode==="light"?"bg-white" : "panel-mode-"+mode} rounded-md shadow-md m-1 p-2 transtion duration-500`}>
        <div className={`flex flex-col w-full header-mode-${mode}`}>
          <h4>Flow Efficiency</h4>
          <div className={`flex flex-col w-full text-[10px] max-h-[50px] overflow-y-auto rounded-lg shadow-lg border-mode-${mode} p-2 mt-2`}>
            {`A lower flow efficiency means work items are spending more time queued up or waiting for the next step in the process, 
            leading to longer lead times. Analyzing where the major delays occur within the workflow to identify potential bottlenecks. 
            Investigate the reasons behind the increased wait times, such as inadequate resource allocation, unclear dependencies, 
            complex handoffs between teams, or poorly defined processes. Once bottlenecks are identified, 
            implement targeted strategies to reduce wait times, like streamlining processes, addressing resource constraints, or 
            improving communication between teams.`}
          </div>
        </div>
        <div className={`flex w-full h-[80%] pt-3 fade-in`}>
          <MultiSeriesBarChart
            label = "Month"
            labels = {flowData.flowEfficiency.map(item => item["labels"])|| []}
            datasets = {[
              {
                label: '',
                values: flowData.flowEfficiency.map(item => item["values"]) || [],
                type: 'line',
                borderColor: 'rgba(0, 200, 0, 1)',
                backgroundColor: 'rgba(0, 200, 0, 1)',
                pointBackgroundColor: 'rgba(0, 200, 0, 1)',
                borderDash: [0,0],
                pointStyle:'circle'
              },
              {
                label: 'Trend',
                values: flowData.flowEfficiency.map(item => item["trend"]) || [],
                type: 'line',
                borderColor: 'rgba(200, 200, 200, 1)',
                backgroundColor: 'rgba(200, 200, 200, 1)',
                pointBackgroundColor: 'rgba(200, 200, 200, 1)',
                borderDash: [5,5],
                pointRadius: "0",
                yAxisID: "yPrimary"
              }    
            ]}
            xAxisTitle = ""
            yAxisTitle = "Active Hours / Total Hours"
            mode = {mode}
            showLegend = {true}
            primaryAxisLabelSuffix = "%"
            primaryAxisMultiplier = {100}
          />
        </div>
     </div>

     <div className={`flex flex-col w-full md:w-[500px] h-[400px] ${mode==="light"?"bg-white" : "panel-mode-"+mode} rounded-md shadow-md m-1 p-2 transtion duration-500`}>
        <div className={`flex flex-col w-full header-mode-${mode}`}>
          <h4>Flow Velocity</h4>
          <div className={`flex flex-col w-full max-h-[50px] overflow-y-auto text-[10px] rounded-lg shadow-lg border-mode-${mode} p-2 mt-2`}>
            {`To improve throughput, investigate factors such as bottlenecks, 
            impediments, or other issues and check for excessive WIP and optimal batch sizes.`}
          </div>
        </div>
        <div className={`flex w-full h-[80%] pt-3 fade-in`}>
          <MultiSeriesBarChart
            label = "Month"
            labels = {flowData.flowVelocity.map(item => item["labels"])|| []}
            datasets = {[
              {
                label: "Features per Week",
                values: flowData.flowVelocity.map(item => item["values"]) || [],
                type: 'line',
                borderColor: 'orange',
                backgroundColor: 'orange',
                pointBackgroundColor: 'orange',
                borderDash: [0,0],
                pointStyle:'circle'
              },
              {
                label: 'Trend',
                values: flowData.flowVelocity.map(item => item["trend"]) || [],
                type: 'line',
                borderColor: 'rgba(200, 200, 200, 1)',
                backgroundColor: 'rgba(200, 200, 200, 1)',
                pointBackgroundColor: 'rgba(200, 200, 200, 1)',
                borderDash: [5,5],
                pointRadius: "0"
              }    
            ]}
            xAxisTitle = ""
            yAxisTitle = "Features per Week"
            mode = {mode}
            showLegend = {true}
          />
        </div>
     </div>

     <div className={`flex flex-col w-full md:w-[500px] h-[400px] ${mode==="light"?"bg-white" : "panel-mode-"+mode} rounded-md shadow-md m-1 p-2 transtion duration-500`}>
        <div className={`flex flex-col w-full header-mode-${mode}`}>
          <h4>Flow Time</h4>
          <div className={`flex flex-col w-full text-[10px] max-h-[75x] overflow-y-auto rounded-lg shadow-lg border-mode-${mode} p-2 mt-2`}>
            {`An increasing Flow Time trend indicates that features are taking longer to complete 
            and suggests potential bottlenecks or inefficiencies within the workflow that need to be addressed. 
            When flow time increases, consider looking at factors like excessive work in progress (WIP), 
            complex handoffs between teams, unnecessary delays, or inadequate resource allocation.`}
          </div>
        </div>
        <div className={`flex w-full h-[80%] pt-3 fade-in`}>
          <MultiSeriesBarChart
            label = "Month"
            labels = {flowData.flowTime.map(item => item["labels"])|| []}
            datasets = {[
              {
                label: "Days Per Feature",
                values: flowData.flowTime.map(item => item["values"]) || [],
                type: 'line',
                borderColor: 'rgb(200,0,100)',
                backgroundColor: 'rgb(200,0,100)',
                pointBackgroundColor: 'rgb(200,0,100)',
                borderDash: [0,0],
                pointStyle:'circle'
              },
              {
                label: 'Trend',
                values: flowData.flowTime.map(item => item["trend"]) || [],
                type: 'line',
                borderColor: 'rgba(200, 200, 200, 1)',
                backgroundColor: 'rgba(200, 200, 200, 1)',
                pointBackgroundColor: 'rgba(200, 200, 200, 1)',
                borderDash: [5,5],
                pointRadius: "0"
              }    
            ]}
            xAxisTitle = ""
            yAxisTitle = "Days per Week"
            mode = {mode}
            showLegend = {true}
          />
        </div>
     </div>

     <div className={`flex flex-col w-full md:w-[500px] h-[400px] ${mode==="light"?"bg-white" : "panel-mode-"+mode} rounded-md shadow-md m-1 p-2 transtion duration-500`}>
        <div className={`flex flex-col w-full header-mode-${mode}`}>
          <h4>Flow Predictability</h4>
          <div className={`flex flex-col w-full text-[10px] max-h-[50px] overflow-y-auto rounded-lg shadow-lg border-mode-${mode} p-2 mt-2`}>
            {`Variance in flow predictability highlights potential issues with planning, technology, or organizational performance,
             making it difficult to reliably forecast future deliveries; essentially, it means the team's ability to deliver work 
             as planned is unreliable and fluctuates significantly.`}
          </div>
        </div>
        <div className={`flex w-full h-[80%] pt-3 fade-in`}>
          <MultiSeriesBarChart
            label = "Month"
            labels = {flowData.flowPredictability.map(item => item["labels"])|| []}
            datasets = {[
              {
                label: '90% Confidence',
                values: flowData.flowPredictability.map(item => item["90_pct"]) || [],
                type: 'line',
                borderColor: 'rgba(0, 200, 0, 1)',
                backgroundColor: 'rgba(0, 200, 0, 1)',
                pointBackgroundColor: 'rgba(0, 200, 0, 1)',
                borderDash: [0,0],
                pointStyle:'circle',
                yAxisID: "yPrimary"
              },
              {
                label: '80% Confidence',
                values: flowData.flowPredictability.map(item => item["80_pct"]) || [],
                type: 'line',
                borderColor: 'rgba(0, 100, 225, 1)',
                backgroundColor: 'rgba(0, 100, 225, 1)',
                pointBackgroundColor: 'rgba(0, 100, 225, 1)',
                borderDash: [0,0],
                pointStyle:'rect',
                yAxisID: "yPrimary"
              },
              {
                label: '70% Confidence',
                values: flowData.flowPredictability.map(item => item["70_pct"]) || [],
                type: 'line',
                borderColor: 'orange',
                backgroundColor: 'orange',
                pointBackgroundColor: 'orange',
                borderDash: [0,0],
                pointStyle:'triangle',
                yAxisID: "yPrimary"
              }      
            ]}
            xAxisTitle = ""
            yAxisTitle = "Active Hours / Total Hours"
            mode = {mode}
            showLegend = {true}
            primaryAxisLabelSuffix = "%"
            primaryAxisMultiplier = {100}
          />
        </div>
     </div>

     <div className={`flex flex-col w-full md:w-[500px] h-[400px] ${mode==="light"?"bg-white" : "panel-mode-"+mode} rounded-md shadow-md m-1 p-2 fade-in transtion duration-500`}>
        <div className={`flex flex-col w-full header-mode-${mode}`}>
          <h4>Flow Distribution</h4>
          <div className={`flex flex-col w-full text-[10px] max-h-[50px] overflow-y-auto rounded-lg shadow-lg border-mode-${mode} p-2 mt-2`}>
            {`A heavy focus on features means there is uneven distribution across the different types of work items. 
            Carefully evaluate the priorities of different work types and actively manage the work queue to ensure 
            a balanced mix of tasks to maintain quality and mitigate risk.`}
          </div>
        </div>
        <div className={`flex w-full h-[80%] pt-3 fade-in transtion duration-500`}>
          <DonutChart
            labels = {flowData.flowDistribution.map(item => item["labels"])|| []}
            values = {flowData.flowDistribution.map(item => item["values"])|| []}
            cutout = {"0"}
            measureLabel = "Number of Features"
          />
        </div>
     </div>


     { 
      data.length>0 && 
      <div className={`flex flex-col w-full md:w-[1500px] h-[800px] overflow-auto ${mode==="light"?"bg-white" : "panel-mode-"+mode} rounded-md shadow-md m-1 p-2 transtion duration-500`}>
        
        <div className={`flex w-[3000px] h-[100%]`}>
          <Table 
              data = {data}
              includeRowSelect = {false}
              includeEditRow = {true}
              formatHeader = {true}
              onCellClicked = {(record)=>handleRecordSelect(record)}
              hiddenColumns = {hiddenColumns}
              mode = {mode}
              distributeColumns={false}
              suppressSizeToFit={false}
          />
        </div>
      </div>
      }
      
    </div>
  )
}

export default Summary
