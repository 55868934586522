import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import * as oomnielabsApi from '../../../apis/oomnielabs'
import FlowLoad from './FlowLoad';
import FlowEfficiency from './FlowEfficiency';
import FlowVelocity from './FlowVelocity';
import FlowTime from './FlowTime';
import * as flowData from '../data/data'
import MultiSeriesBarChart from './MultiSeriesBarChart';
import DonutChart from './DoughnutChart';
import Table from '../../../components/Table'

const PredictiveAnalytics = ({contextApi}) => {

  const mode = useSelector(state => state.environment.mode);

  const [filterData, setFilterData] = useState([])
  const [data, setData] = useState([])

  const [formData, setFormData] = useState({
    start_date:"",
    end_date:"",
    time_interval:"",
    filters: filterData
  })


  const [selectedRecord, setSelectedRecord] = useState([])

  const getData = async()=>{
    const query = `SELECT * FROM features;`
    const response = await oomnielabsApi.getPythonData(query)
    setData(response)
  }

  useEffect(()=>{
    getData()
  },[])

  const handleRecordSelect = async (record)=>{
    const query = `SELECT * FROM features WHERE id='${record.id}' LIMIT 1;`
    const response = await oomnielabsApi.getPythonData(query, "main")
    setSelectedRecord(response[0])
  }

  const hiddenColumns = ["id", "record_created"]

  
  return (
    <div className={`flex flex-col h-screen w-full min-w-[750px] fade-in items-center m-auto overflow-y-auto ${mode==="light" && "bg-[rgb(245,245,245)]"}`}>
      
      <div className={`flex w-[80%] h-[500px] m-2 max-w-[1500px]`}>
        <FlowLoad />
      </div>

      <div className={`flex w-[80%] h-[500px] m-2 max-w-[1500px]`}>
        <FlowEfficiency />
      </div>

      <div className={`flex w-[80%] h-[500px] m-2 max-w-[1500px]`}>
        <FlowVelocity />
      </div>

      <div className={`flex w-[80%] h-[500px] m-2 max-w-[1500px]`}>
        <FlowTime />
      </div>

      <div className={`flex w-[80%] h-[500px] m-2 max-w-[1500px] justify-between`}>

          <div className={`flex flex-col w-[50%] h-[400px] ${mode==="light"?"bg-white" : "panel-mode-"+mode} rounded-md shadow-md m-1 p-2 transtion duration-500`}>
            <div className={`flex flex-col w-full header-mode-${mode}`}>
              <h4>Flow Predictability</h4>
              <div className={`flex flex-col w-full text-[10px] max-h-[50px] overflow-y-auto rounded-lg shadow-lg border-mode-${mode} p-2 mt-2`}>
                {`Variance in flow predictability highlights potential issues with planning, technology, or organizational performance,
                making it difficult to reliably forecast future deliveries; essentially, it means the team's ability to deliver work 
                as planned is unreliable and fluctuates significantly.`}
              </div>
            </div>
            <div className={`flex w-full h-[80%] pt-3 fade-in`}>
              <MultiSeriesBarChart
                label = "Month"
                labels = {flowData.flowPredictability.map(item => item["labels"])|| []}
                datasets = {[
                  {
                    label: '90% Confidence',
                    values: flowData.flowPredictability.map(item => item["90_pct"]) || [],
                    type: 'line',
                    borderColor: 'rgba(0, 200, 0, 1)',
                    backgroundColor: 'rgba(0, 200, 0, 1)',
                    pointBackgroundColor: 'rgba(0, 200, 0, 1)',
                    borderDash: [0,0],
                    pointStyle:'circle',
                    yAxisID: "yPrimary"
                  },
                  {
                    label: '80% Confidence',
                    values: flowData.flowPredictability.map(item => item["80_pct"]) || [],
                    type: 'line',
                    borderColor: 'rgba(0, 100, 225, 1)',
                    backgroundColor: 'rgba(0, 100, 225, 1)',
                    pointBackgroundColor: 'rgba(0, 100, 225, 1)',
                    borderDash: [0,0],
                    pointStyle:'rect',
                    yAxisID: "yPrimary"
                  },
                  {
                    label: '70% Confidence',
                    values: flowData.flowPredictability.map(item => item["70_pct"]) || [],
                    type: 'line',
                    borderColor: 'orange',
                    backgroundColor: 'orange',
                    pointBackgroundColor: 'orange',
                    borderDash: [0,0],
                    pointStyle:'triangle',
                    yAxisID: "yPrimary"
                  }      
                ]}
                xAxisTitle = ""
                yAxisTitle = "Active Hours / Total Hours"
                mode = {mode}
                showLegend = {true}
                primaryAxisLabelSuffix = "%"
                  primaryAxisMultiplier = {100}
                />
              </div>
          </div>

        <div className={`flex flex-col w-[50%]md:w-[500px] h-[400px] ${mode==="light"?"bg-white" : "panel-mode-"+mode} rounded-md shadow-md m-1 p-2 fade-in transtion duration-500`}>
            <div className={`flex flex-col w-full header-mode-${mode}`}>
              <h4>Flow Distribution</h4>
              <div className={`flex flex-col w-full text-[10px] max-h-[50px] overflow-y-auto rounded-lg shadow-lg border-mode-${mode} p-2 mt-2`}>
                {`A heavy focus on features means there is uneven distribution across the different types of work items. 
                Carefully evaluate the priorities of different work types and actively manage the work queue to ensure 
                a balanced mix of tasks to maintain quality and mitigate risk.`}
              </div>
            </div>
            <div className={`flex w-full h-[80%] pt-3 fade-in transtion duration-500`}>
              <DonutChart
                labels = {flowData.flowDistribution.map(item => item["labels"])|| []}
                values = {flowData.flowDistribution.map(item => item["values"])|| []}
                cutout = {"0"}
                measureLabel = "Number of Features"
              />
            </div>
        </div>

      </div>

      <div className={`flex w-[80%] h-[800px] m-2 max-w-[1500px] justify=center`}>

         { 
          data.length>0 && 
          <div className={`flex flex-col w-full md:w-[1500px] h-[800px] overflow-auto ${mode==="light"?"bg-white" : "panel-mode-"+mode} rounded-md shadow-md m-1 p-2 transtion duration-500`}>
            
            <div className={`flex w-[3000px] h-[100%]`}>
              <Table 
                  data = {data}
                  includeRowSelect = {false}
                  includeEditRow = {true}
                  formatHeader = {true}
                  onCellClicked = {(record)=>handleRecordSelect(record)}
                  hiddenColumns = {hiddenColumns}
                  mode = {mode}
                  distributeColumns={false}
                  suppressSizeToFit={false}
              />
            </div>
          </div>
          }

      </div>

      {/* { 
      data.length>0 && 
      <div className={`flex flex-col w-full md:w-[1500px] h-[800px] overflow-auto ${mode==="light"?"bg-white" : "panel-mode-"+mode} rounded-md shadow-md m-1 p-2 transtion duration-500`}>
        
        <div className={`flex w-[3000px] h-[500px]`}>
          <Table 
              data = {data}
              includeRowSelect = {false}
              includeEditRow = {true}
              formatHeader = {true}
              onCellClicked = {(record)=>handleRecordSelect(record)}
              hiddenColumns = {hiddenColumns}
              mode = {mode}
              distributeColumns={false}
              suppressSizeToFit={false}
          />
        </div>
      </div>
      } */}

  
      
    </div>
  )
}

export default PredictiveAnalytics
