import React, {useState, useRef} from 'react'
import { useSelector } from 'react-redux'
import * as formatValue from '../../../functions/formatValue.js'
import FormatHTML from './FormatHTML.js'
import StarRating from './StarRating.js'
import * as oomnielabsApi from '../../../apis/oomnielabsPythonApi.js'
import * as styleFunctions from '../../../functions/styleFunctions.js'
import Svg from '../../../components/Svg.js'
import FloatingPanel from '../../../components/FloatingPanel.js'

const Chat = ({chat}) => {

  const mode = useSelector(state=>state.environment.mode) 

  const [selectedRating, setSelectedRating] = useState(chat.feedback)
  const [hovered, setHovered] = useState("")

  const [clipboardContents, setClipboardContents] = useState(null)
  const [showPanel, setShowPanel] = useState(false)


  const handleSelectedRating = async(rating)=>{

    setSelectedRating(rating)
    const columnValues = {  
      feedback: rating
    }
    const conditions = {
      id: chat.id
    }
    await oomnielabsApi.updateRecord("chats",columnValues,conditions)
    
  }

  const handleCopy = (copiedContents) => {
    
    if (copiedContents) {
      navigator.clipboard
        .writeText(copiedContents)
        .then(() => {
          setShowPanel(true)
        })
        .catch((err) => {
          console.error('Error copying text: ', err);
        });
        setClipboardContents(copiedContents)
    }

  };

  return (
    <div key={chat.id} className={`flex flex-col w-full`}>

        <label className={`secondary-color-mode-${mode} text-[12px] m-1 fade-in`}>
            {chat.created_at && formatValue.GMTToLocalDateTime(chat.created_at).length>0}
        </label>
        
        <div className={`chat-prompt-mode-${mode} fade-in`}>
          <FormatHTML text={chat.prompt.length>0 ?  chat.prompt : null}/>
        </div>
        
        <div className={`flex flex-col w-full items-end`}>
          <div className={`flex w-full justify-end`}>
            
            <StarRating 
              selectedRating={selectedRating} 
              setSelectedRating={handleSelectedRating}
              hint="Rate this response"
            />

            
              <div 
                className={`ms-3`} 
                onClick={()=>handleCopy(chat.response)} 
                onMouseOver={()=>setHovered("copyIcon")}
                onMouseLeave={()=>setHovered("")} 
                title="Copy"
              >
                <Svg
                  iconName ="CopyIcon"
                  height = "30px"
                  width = "30px"
                  fillColor = {styleFunctions.getColor(`${
                    hovered ==="copyIcon" ? "icon-color-mode-"+mode+"-clicked"
                    : "icon-color-mode-"+mode
                  }`)}
                  fillOpacity = "1"
                />
              </div>
            

            
           </div>

          <div className={`chat-response-mode-${mode} fade-in`}>
            <FormatHTML text={chat.response.length>0 ? chat.response : null}/>
          </div>

        </div>

        {showPanel && 
        <FloatingPanel height="200px" width="300px" rounded="20px" border="3px solid rgba(200,200,200,0.5)" displayPanel={setShowPanel}>
          <div className={`flex w-full h-[100%] justify-center text-center items-center font-bold`}>
            Response copied
          </div>
        </FloatingPanel>}
    </div>
  )
}

export default Chat
