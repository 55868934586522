import React, { useState } from "react";
import "../App.css";

const App = () => {
  const initialServers = Array.from({ length: 40 }, (_, index) => ({
    id: index + 1,
    rackId: index < 20 ? 1 : 2, // First 20 servers in rack 1, next 20 in rack 2
  }));

  const [servers, setServers] = useState(initialServers);

  const handleDragStart = (event, serverId) => {
    event.dataTransfer.setData("serverId", serverId);
  };

  const handleDrop = (event, targetRackId) => {
    event.preventDefault();
    const serverId = parseInt(event.dataTransfer.getData("serverId"), 10);

    setServers((prevServers) =>
      prevServers.map((server) =>
        server.id === serverId ? { ...server, rackId: targetRackId } : server
      )
    );
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const renderRack = (rackId) => {
    return (
      <div
        className="flex w-[300px]"
        onDrop={(event) => handleDrop(event, rackId)}
        onDragOver={handleDragOver}
      >
        <h3>Rack {rackId}</h3>
        <div className="servers">
          {servers
            .filter((server) => server.rackId === rackId)
            .map((server) => (
              <div
                key={server.id}
                className="server"
                draggable
                onDragStart={(event) => handleDragStart(event, server.id)}
              >
                Server {server.id}
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <div className={`flex flex-col w-full h-screen justify-center text-center`}>
      <h1>Server Rack Management</h1>
      <div className="flex w-full justify-center">
        {renderRack(1)}
        {renderRack(2)}
      </div>
    </div>
  );
};

export default App;

