import React, { useEffect } from 'react';
import ProtectedRoute from './components/ProtectedRoute';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setGlobalData } from './redux/slices/mainSlice.js';
import Header from "./components/Header";
import AppList from './components/AppList.js';
import SignIn from './components/SignIn.js';

import * as oomnielabsApi from './apis/oomnielabs.js'

import OomnieChat  from './modules/oomnieChat/App.js'
import OomnieInsightsGenie  from './modules/oomnieInsightsGenie/App.js'
import OomnieDocGenie  from './modules/oomnieDocGenie/App.js'
import OomnieWorkflowGenie  from "./modules/oomnieWorkflowGenie/App.js"
import DataCenterVisualizer  from './modules/datacenter/App.js'
import DataCenterPowerOptimizer  from './modules/datacenterPowerOptimizer/App.js'
import LifeCycleManager  from './modules/life_cycle_manager/App.js'
import Playground from './modules/playground/App.js'
import OomnieDevFlow from './modules/oomnieDevFlow/App.js'

import Settings from './components/Settings.js';

function App() {

  const dispatch = useDispatch();

  const mode = useSelector((state) => state.environment.mode);
  const globalData = useSelector((state) => state.main.globalData);
  const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated);
  const user = useSelector((state) => state.authentication.isAuthenticated);

  const contextApi = {
    isAuthenticated,
    mode,
    user
  }

  const getDataBaseTables = async ()=>{
    const query = `SELECT table_name FROM information_schema.tables WHERE table_schema = 'public' AND table_type = 'BASE TABLE';`
    const response = await oomnielabsApi.getPythonData(query,"main")
    const tableList = {"db_table_list":response}
    console.log(tableList)
    dispatch(setGlobalData({...globalData,tableList}))
  }

  useEffect(()=>{
    getDataBaseTables()
  },[])

  useEffect(() => {
    console.log("is authenticated:", isAuthenticated);
    // dispatch(clearAllStorage()) // Remove this unless there's a condition for calling it

  }, [isAuthenticated]);

  return (
    <div className={`body-mode-${mode} fade-in`}>
      
      <Router>
      
      {isAuthenticated && <Header />}
      
        <Routes>
          <Route path="/" element={<ProtectedRoute><AppList /></ProtectedRoute>} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/apps" element={<ProtectedRoute><AppList /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
          <Route path="/oomnieChat/*" element={<ProtectedRoute><OomnieChat contextApi={contextApi}/></ProtectedRoute>} />
          <Route path="/oomnieInsightsGenie/*" element={<ProtectedRoute><OomnieInsightsGenie contextApi={contextApi}/></ProtectedRoute>} />
          <Route path="/oomnieDocGenie/*" element={<ProtectedRoute><OomnieDocGenie contextApi={contextApi} /></ProtectedRoute>} />
          <Route path="/oomnieWorkflowGenie/*" element={<ProtectedRoute><OomnieWorkflowGenie contextApi={contextApi} /></ProtectedRoute>} />
          <Route path="/oomnieLifeCycleGenie/*" element={<ProtectedRoute><LifeCycleManager contextApi={contextApi} /></ProtectedRoute>} />
          <Route path="/dataCenterVisualizer/*" element={<ProtectedRoute><DataCenterVisualizer contextApi={contextApi} /></ProtectedRoute>} />
          <Route path="/dataCenterPowerOptimizer/*" element={<ProtectedRoute><DataCenterPowerOptimizer contextApi={contextApi} /></ProtectedRoute>} />
          <Route path="/oomnieDevFlow/*" element={<ProtectedRoute><OomnieDevFlow contextApi={contextApi} /></ProtectedRoute>} />
          <Route path="/playground/*" element={<ProtectedRoute><Playground contextApi={contextApi} /></ProtectedRoute>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

