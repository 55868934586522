import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import ColumnChart from './ColumnChart.js'
import * as oomnielabsApi from '../../../apis/oomnielabs.js'
import MultiInput from '../../../components/MultiInput.js'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Svg from'../../../components/Svg.js'
import ChartFilter from './ChartFilter.js';
import MultiSeriesBarChart from './MultiSeriesBarChart.js';


const FlowEfficiency = (props) => {

  const mode = useSelector(state => state.environment.mode);

  const [showFilter, setShowFilter] = useState(false)

  const [formData, setFormData] = useState({})
  const [data, setData] = useState([])
  const [values, setValues] = useState([])
  const [labels, setLabels] = useState([])
  const [averageValues, setAverageValues] = useState([])
  const [movingAverage, setMovingAverage] = useState([])
  const [forecastLabels, setForecastLabels] = useState([])
  const [forecastValues, setForecastValues] = useState([])
  const [forecastSummary, setForecastSummary] = useState(null)

  const getData = async()=>{

    const query = `SELECT 
      TO_CHAR(DATE_TRUNC('month', start_date), 'Mon YYYY') AS "Month", 
      SUM(active_work_time) AS "Total Active Work Time", 
	    SUM(total_work_time) AS "Total Work Time", 
      CASE WHEN SUM(total_work_time) > 0 THEN (SUM(active_work_time)::float / SUM(total_work_time)::float) ELSE 0 END AS "Efficiency" 
      FROM features 
      GROUP BY DATE_TRUNC('month', start_date)
      ORDER BY DATE_TRUNC('month', start_date);`

    const dbName = "main"
    const response = await oomnielabsApi.getPythonData(query, dbName)
    const labels = response.map(item => item["Month"]);
    const values = response.map(item => item["Efficiency"]);

    const trendAnalysis = await oomnielabsApi.pythonApp("trend_analysis","analyze_trend",{data: values})
    const averages = response.map(item => trendAnalysis.average);

    setValues(values)
    setLabels(labels)
    setAverageValues(averages)
    setMovingAverage(trendAnalysis.moving_average)

    const forecastLabels = ["1-Month", "3-Months", "6-Months"]
    const forecastValues = [trendAnalysis.linear_forecasts[0], trendAnalysis.linear_forecasts[2], trendAnalysis.linear_forecasts[5]]
  
    setForecastLabels(forecastLabels)
    setForecastValues(forecastValues)

    const forecast_change = (forecastValues.slice(-1)[0] - values.slice(-1)[0]) / values.slice(-1)[0] * 100
    const forecast_summary = `Efficiency is predicted to ${forecast_change <0 ? "decreased" : "increase"} by ${forecast_change.toFixed(1)}% over 6 months`
    setForecastSummary(forecast_summary)
  }

  useEffect(()=>{
    getData()
    
  },[])

  return (

    <div className={`flex h-[500px] w-full fade-in  flex-wrap ${mode==="light" && "bg-[rgb(245,245,245)]"}`}>
      
      {/* Main Chart */}
      <div className={`flex flex-col w-full h-[100%] overflow-hidden ${mode==="light"?"bg-white" : "panel-mode-"+mode} rounded-md shadow-md m-2 transtion duration-500`}>
        
        {/* Chart Header */}
        <div className={`flex w-full h-[50px] items-center header-strip-mode-${mode} ps-3 pe-3 justify-between`}>
          <h3>Flow Efficiency</h3>
          <div title={"Filter"} onClick={(e)=>setShowFilter(!showFilter)}>
            <Svg 
              iconName = {"FilterIcon"}
              height  = {30}
              width  = {30}
              fillColor  = {"rgb(150,150,150"}
              fillOpacity  = {1}
              isHovered  = {false}
              cursor  = {"pointer"}å
            />
          </div>
        </div>

        {/* Chart Body */}  
       <div className={`flex w-full h-[100%] rounded-md`}>
          
          {/* Bar Chart */}
          <div className={`flex flex-col items-center w-3/4 h-[100%] p-3`}>
            <h4>Trend</h4>
            <div className={`flex flex-col w-[90%] text-orange-400 rounded-md text-[14px] p-2 mt-2`}>
            {`A lower flow efficiency means work items are spending more time queued up or waiting for the next step in the process, 
            leading to longer lead times. Analyzing where the major delays occur within the workflow to identify potential bottlenecks. 
            Investigate the reasons behind the increased wait times, such as inadequate resource allocation, unclear dependencies, 
            complex handoffs between teams, or poorly defined processes. Once bottlenecks are identified, 
            implement targeted strategies to reduce wait times, like streamlining processes, addressing resource constraints, or 
            improving communication between teams.`}
            </div>
            <div className="flex w-full h-[80%]">
              <MultiSeriesBarChart
                 label = "Month"
                 labels = {labels || []}
                 datasets = {[
                   {
                     label: 'Moving Average',
                     values: movingAverage,
                     type: 'line',
                     borderColor: 'rgba(0, 100, 0, 1)',
                     backgroundColor: 'rgba(0, 0, 0, 0)',
                     pointBackgroundColor: 'rgba(0, 0, 225, 1)'
                   },
                   {
                    label: 'Monthly Average',
                    values: averageValues || [],
                    type: 'line',
                    borderColor: 'rgba(200, 200, 200, 1)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    pointBackgroundColor: 'rgba(200, 200, 200, 0)'
                  },
                   {
                    label: 'Active Hours / Total Hours',
                    values:  values || [],
                    backgroundColor: 'rgba(0, 150, 225, 0.6)',
                  }   
                 ]}
                 xAxisTitle = ""
                 yAxisTitle = 'Active Hours / Total Hours'
                 secondaryYAxisTitle = "Moving Average"
                 mode = {mode}
              />
            </div>
          </div>

          {/* Forecast Chart */}
          <div className={`flex flex-col h-[100%] w-1/4 p-3 text-center`}>
            <h4>Forecast</h4>
            <div className="flex w-full text-[14px] h-[40px] justify-center items-center text-center mb-2  text-orange-400">
              {forecastSummary}
            </div>
            <div className="flex w-full h-[300px]">
              <MultiSeriesBarChart
                label = "Period"
                labels = {forecastLabels || []}
                datasets = {[
                  {
                    label: 'Efficiency',
                    values: forecastValues || [],
                    backgroundColor: 'rgba(0, 225, 150, 0.6)',
                  },
                ]}
                barColor = "rgba(0,200,180,0.5)"
                yAxisTitle = "Active Hours / Total Hours"
                xAxisTitle = ""
                mode = {mode}
                showLegend = {false}
              />
            </div>
          </div>

        </div>
      </div>


      {/* Filter Menu */}
      {showFilter && 
        <ChartFilter 
          showFilter = {showFilter}
          setShowFilter = {setShowFilter}
          updateFormData = {setFormData}
        />
      }

    </div>
  )
}

export default FlowEfficiency
