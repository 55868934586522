import React, { useState, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux'
import * as oomnielabsApi from '../../apis/oomnielabsPythonApi'

import Topic from './components/Topic.js'
import Chat from './components/Chat.js'
import PromptInput from './components/PromptInput.js'


const App = () => {

  const mode = useSelector((state) => state.environment.mode);
  const user = useSelector((state) => state.authentication.user);

  const [userInput, setUserInput] = useState("");
  const [response, setResponse] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [currentTopic, setCurrentTopic] = useState("");
  const [topics, setTopics] = useState([]);
  const [streamedData, setStreamedData] = useState("");
  const [LLM, setLLM] = useState("chatgpt")

  const divRef = useRef(null);

  const getChatHistory = async () => {

    console.log("getting chat history...")
    const query = `SELECT * FROM "chats" where "user"='${user.id}';`;
    let importedData = await oomnielabsApi.getData(query);

    if (importedData.length > 0) {
      const chatHistoryData = importedData.sort(
        (a, b) => (new Date(b.created_at)) - (new Date(a.created_at))
      );

      const topicList = [...new Set(chatHistoryData.map((item) => item.topic))];
      setTopics(topicList);
      setCurrentTopic(chatHistoryData[0].topic);

      importedData = chatHistoryData.sort(
        (a, b) => (new Date(a.created_at)) - (new Date(b.created_at))
      )
      setChatHistory(importedData);
    }

    return importedData
  };

  useEffect(() => {
    getChatHistory();
  }, []);

 
  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTo({
        top: divRef.current.scrollHeight+100,
        behavior: 'smooth',
      });
    }
  }, [response, currentTopic, chatHistory]);



  return (
    <div className={`flex w-full h-screen overflow-hidden transition duration-500`}>
      
      {/* Side Panel */}
      {chatHistory.length > 0 && (
        <div
          className={`hidden md:flex flex-col w-[400px] h-screen panel-mode-${mode} p-3 pt-10 overflow-y-auto transition duration-500`}
          onClick={() => setCurrentTopic("")}
        >
          {topics.length > 0 &&
            topics.map(
              (topic, index) =>
                topic.length > 0 && (
                  <Topic
                    key={index}
                    index={index}
                    topic={topic}
                    currentTopic={currentTopic}
                    setCurrentTopic={setCurrentTopic}
                    getChatHistory={getChatHistory}
                  />
                )
            )}
        </div>
      )}


      {/* Main Panel */}
      <div className={`flex w-full flex-col items-center p-5 h-screen overflow-hidden`}>
        
        <h2 className={`title-mode-${mode} mb-5 text-left`}>Oomnie Chat</h2>

        {/* Prompt input */}
        <div className={`flex flex-col w-full`}>
          <PromptInput
            userInput={userInput}
            setUserInput={setUserInput}
            setResponse={setResponse}
            setCreatedAt={setCreatedAt}
            getChatHistory={getChatHistory}
            chatHistory={chatHistory}
            setChatHistory={setChatHistory}
            currentTopic={currentTopic}
            setCurrentTopic={setCurrentTopic}
            topics = {topics}
            setTopics = {setTopics}
          />
        </div>

        {/* Chats */}
        <div className={`flex w-full h-screen flex-col p-5`}>
          <h4 className={`border-b-[1px] border-b-gray-400] mb-3 p-1`}>{currentTopic}</h4>
          <div
            ref={divRef}
            className={`flex flex-col w-full p-5 h-[80%] overflow-y-auto mt-5 pb-[200px]`}
          >
            {chatHistory.length > 0 &&
              chatHistory.map((chat, index) =>
                  chat.topic === currentTopic && <Chat key={index} chat={chat} />
              )}
          </div>
        </div>
      </div>

    </div>
  );
};

export default App;
