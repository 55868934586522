import React from 'react';
import { useSelector } from 'react-redux';

import Summary from "./components/Summary.js";
import PredictiveAnalytics from './components/PredictiveAnalytics.js';
import Navbar from './components/Navbar.js';

// Set application details:
export const appName = "OonnieIQ"
export const logoFile = "Oomnitza_logo.png"
 
function App({contextApi}) {

  const mode = useSelector(state => state.environment.mode);
  const currentPage = useSelector(state => state.oomnieDevFlow.currentPage);

  return (
    <div className={`flex flex-col w-full h-screen overflow-hidden transition duration-500 body-mode-${mode} `}>
        <Navbar contextApi={contextApi}/>
        {currentPage ==="summary" && <Summary contextApi={contextApi} />}
        {currentPage ==="predictive_analytics" && <PredictiveAnalytics contextApi={contextApi} />}
    </div>
  );
}

export default App;

