import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setMode } from '../redux/slices/envSlice.js';
import * as oomnielabsApi from '../apis/oomnielabs.js'

function AppList() {

  const dispatch = useDispatch();
  const [displayPanel, setDisplayPanel] = useState(false)  
  const mode = useSelector((state) => state.environment.mode);

  const apps = [
    {id: 1, name: "dataCenterVisualizer", label: "Data Center Visualizer", link: "/dataCenterVisualizer", icon: `${oomnielabsApi.icons}/data_center_icon.png`, description:"Visualize and manage data center assets through 3D and 2D interactive experience"},
    {id: 2, name: "oomnieInsightGenie", label: "Oomnie Insight Genie", link: "/oomnieInsightsGenie", icon: `${oomnielabsApi.icons}/analytics_icon.png`, description:"Maximize business value impact of technlogy asset management through predictive analytics, forecasting, and powerful visualizations."},
    {id: 3, name: "oomnieDocGenie", label: "Oomnie Doc Genie", link: "/oomnieDocGenie", icon: `${oomnielabsApi.icons}/document_icon.png`, description:"Converse with an AI agent to assist you with all your asset management needs"},
    {id: 4, name: "oomnieWorkflowGenie", label: "Oomnie Workflow Genie", link: "/oomnieWorkflowGenie", icon: `${oomnielabsApi.icons}/workflow_icon.png`, description:"Automate the wokflows end-to-end using AI workflow agents"},
    {id: 5, name: "oomnieChat", label: "Oomnie Chat", link: "/oomnieChat", icon: `${oomnielabsApi.icons}/chat_icon.png`, description:"Ask Oomnie anything you need  help with in a ChatGPT like experience."},
    {id: 6, name: "oomnieDevFlow", label: "Oomnie DevFlow", link: "/oomnieDevFlow", icon: `${oomnielabsApi.icons}/software_development_icon.png`, description:"Dashboard and insights for managing software development flow metrics"},
    {id: 7, name: "oomnieLifeCycleGenie", label: "Oomnie LifeCycle Genie", link: "/oomnieLifeCycleGenie", icon: `${oomnielabsApi.icons}/life_cycle_icon.png`, description:"Manage life cycle of assets"},
    {id: 8, name: "dataCenterPowerOptimizer", label: "Data Center Power Optimizer", link: "/dataCenterPowerOptimizer", icon: `${oomnielabsApi.icons}/life_cycle_icon.png`, description:"Manage life cycle of assets"},
  ]


  const handleRedirect = (url) => {
      window.location.href = url;
  };


  return (
    <div className="flex flex-col w-full fade-in h-[100%] overflow-hidden items-center">
       
       <div className={`flex flex-wrap w-[80%] title-mode-${mode} justify-center mt-5`}>Protoype Apps</div>

       <div className="flex flex-wrap w-full md:w-[800px] justify-center overflow-y-auto mt-5">
          {apps && 
            apps.map((app,index)=>(
              <div 
                key={index} 
                className={`panel-mode-${mode} h-[150px] md:h-[250px] w-full md:w-[200px] rounded-md shadow-lg p-3 m-3 cursor-pointer transition duration-500 hover:scale-110`}
                onClick={(e)=>handleRedirect(app.link)}
                >
                <div className={`text-[20px] primary-color-mode-${mode} mb-3`}>{app.label}</div>
                <div className={`text-[14px] secondary-color-mode-${mode}` }>{app.description}</div>
              </div>
            ))
          }
        </div>
    </div>
  );
}

export default AppList;
