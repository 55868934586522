import React, {useState, useRef, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {setSelectedObjects, setViewSettings, setShowObjects} from '../../../redux/slices/datacenterSlice.js'
import { clearStorage } from '../../../redux/slices/datacenterSlice.js'
import fieldOptions from './fieldOptions.json'
import Table from '../../../components/Table.js'
import ThreeDSpace from './ThreeDSpace.js'
import * as oomnielabsApi from '../../../apis/oomnielabs.js'
import RouteOptimizer from './RouteOptimizer.js'

const Inspection = () => {

  const dispatch = useDispatch()


  // useEffect(()=>{
  //   dispatch(clearStorage())
  // },[])

  // Import global states
  const mode = useSelector(state=>state.environment.mode)
  const selectedObjects = useSelector(state=>state.datacenter.selectedObjects)
  const viewSettings = useSelector(state=>state.datacenter.viewSettings)
  const showObjects = useSelector(state => state.datacenter.showObjects);

  // console.log("viewSettings",viewSettings)
  
  // Define Local States
  const [objects, setObjects] = useState([])
  const [schedule, setSchedule] = useState([])
  const [expandSchedule, setExpandSchedule] = useState(true)
  const spaceRef = useRef()

  const [view, setView] = useState("3D")


  const getObjects = async ()=>{
    const rooms = await oomnielabsApi.getPythonTable("rooms")
    const cages = await oomnielabsApi.getPythonTable("cages")
    const cabinets = await oomnielabsApi.getPythonTable("cabinets")
    const servers = await oomnielabsApi.getPythonTable("servers")

    const objects = [...rooms,...cages,...cabinets,...servers]
    setObjects(objects)
  }

  const getSchedule = async ()=>{
    const response = await oomnielabsApi.getPythonTable("inspection_tasks")
    setSchedule(response)
  }

  useEffect(()=>{
    getObjects()
    getSchedule()
  },[])


  const handleSelectedItems = (items)=>{
    console.log("selected Items:", items)
  }

  const handleShowView = (e)=>{
    if(view==="3D"){
      setView("route_plan")
    }else{
      setView("3D")
    }
  }

  const hiddenColumns =["id", "record_created", "schedule_id"]

  return (
    <div className={`flex flex-col w-full h-[100vh]`}>

      <div className={`flex w-full h-[50px] p-1 mb-2`}>
            <div className={`flex w-full h-auto p-3`}>
              <button 
                className={`p-3 button-mode-${mode}`}
                onClick = {(e)=>handleShowView()}
                >{view==="3D"? "Show Route" : "Show 3D"}            
              </button>
            </div>
      </div>

      {/* View */}
      <div className={`flex w-full h-[100%] justify-between`}>
        <div 
          ref = {spaceRef}
          className={`flex w-full fade-in transtiion duration-300`} 
          style={{width: expandSchedule? "50%" : "100%" }}>
          {
          view==="route_plan"?
          <RouteOptimizer />
          :
          <ThreeDSpace
              objects = {objects}
              selectedObjects = {selectedObjects}
              viewSettings = {viewSettings}
              showObjects = {showObjects}
              setSelectedObjects = {(objects)=>dispatch(setSelectedObjects(objects))}
              setShowObjects = {(objects)=>dispatch(setShowObjects(objects))}
              setViewSettings = {(objects)=>dispatch(setViewSettings(objects))}
            />
          }
        </div>


        {schedule.length>0 && 
        <div 
          className={`flex flex-col h-[100%] panel-mode-${mode} overflow-y-auto fade-in transtiion duration-300 right-0`}
          style={{width: expandSchedule? "50%" : "150px" }}>
          
          <div className={`flex w-full h-auto p-3`}>
            <button 
              className={`p-3 button-mode-${mode}`}
              onClick = {(e)=>setExpandSchedule(!expandSchedule)}
              >{expandSchedule? "Hide Schedule" : "Show Schedule"}            
            </button>
          </div>

          {expandSchedule && 
          <div className={`flex flex-col w-full h-[100%] text-center ms-3`}>
            <div className={`flex w-full header-strip-mode-${mode} p-3`}>Inspection Schedule</div>
            <Table
              // tableFieldOptions = {fieldOptions}
              data = {schedule}
              formatHeader = {true}
              mode = {mode}
              hiddenColumns = {hiddenColumns}
              includeRowSelect ={true}
              onRowSelected = {((selectedItems)=>handleSelectedItems(selectedItems))}
            />
          </div>
          }
        </div>
        }

      </div>
      
    </div>
  )
}

export default Inspection

