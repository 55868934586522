import React, { useState } from "react";

const StarRating = ({ totalStars = 5, selectedRating=0, setSelectedRating, hint="" }) => {
  const [hoveredStar, setHoveredStar] = useState(0);

  const handleMouseEnter = (index) => {
    setHoveredStar(index);
  };

  const handleMouseLeave = () => {
    setHoveredStar(0);
  };

  const handleClick = (index) => {
    setSelectedRating(index);
  };

  return (
    <div style={{ display: "flex", cursor: "pointer" }} title={hint}>
      {Array.from({ length: totalStars }, (_, i) => i + 1).map((star) => (
        <svg
          key={star}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill={
            star <= (hoveredStar || selectedRating) ? "gold" : "lightgray"
          }
          onMouseEnter={() => handleMouseEnter(star)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick(star)}
        >
          <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
        </svg>
      ))}
    </div>
  );
};

export default StarRating;
