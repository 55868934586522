import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { 
  Chart as ChartJS, 
  CategoryScale, 
  LinearScale, 
  BarElement, 
  LineElement, 
  Title, 
  Tooltip, 
  Legend, 
  PointElement, 
  LineController 
} from 'chart.js';
import { useSelector } from 'react-redux';
import * as styleFunctions from '../../../functions/styleFunctions';

// Register all necessary ChartJS components only once
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LineController
);

const MultiSeriesBarChart = (props) => {
  const mode = useSelector((state) => state.environment.mode);

  const labels = props.labels; // x-axis labels
  const datasets = props.datasets; // array of datasets for stacked bars and lines
  const yAxisTitle = props.yAxisTitle;
  const secondaryYAxisTitle = props.secondaryYAxisTitle || ''; // Title for secondary y-axis
  const xAxisTitle = props.xAxisTitle;
  const chartTitle = props.chartTitle;
  const subTitle = props.subTitle;
  const stackedX = props.stackedX || false;
  const stackedY = props.stackedY || false;
  const showLegend = props.showLegend;
  const primaryAxisLabelPrefix = props.primaryAxisLabelPrefix || ""
  const primaryAxisLabelSuffix = props.primaryAxisLabelSuffix || ""
  const primaryAxisMultiplier = props.primaryAxisMultiplier || 1
  const secondaryAxisLabelPrefix = props.secondaryAxisLabelPrefix || ""
  const secondaryAxisLabelSuffix = props.secondaryAxisLabelSuffix || ""
  const secondaryAxisMultiplier = props.secondaryAxisMultiplier || 1

  const displayHorizontalGridLines = props.displayHorizontalGridLines || false;
  const displayVerticalGridLines = props.displayVerticalGridLines || false;
  const displaySecondaryAxis = props.displaySecondaryAxis || false

  const data = {
    labels: labels,
    datasets: datasets.map((dataset) => ({
      label: dataset.label,
      data: dataset.values,
      type: dataset.type || 'bar', // Default to 'bar' if type is not specified
      backgroundColor: dataset.type === 'line' ? 'rgba(0,0,0,0)' : dataset.backgroundColor, // Bar colors
      borderColor: dataset.borderColor || 'rgb(255,255,255)', // Border color for bar ds or line
      borderWidth: dataset.type === 'line' ? 2 : 1, // Thicker border for line
      pointBackgroundColor: dataset.type === 'line' ? dataset.pointBackgroundColor || 'rgb(75,192,192)' : undefined, // Line points color
      borderDash: dataset.type === 'line' ? dataset.borderDash || [5, 5] : undefined,
      pointStyle: dataset.type === 'line' && dataset.pointStyle ? dataset.pointStyle : null, // Set marker shape
      pointRadius: dataset.type === 'line' && dataset.pointRadius ? dataset.pointRadius : 3, // Set marker shape
      tension: dataset.type === 'line' ? dataset.tension || 0.4 : undefined, // Smoothness for line
      yAxisID: dataset.yAxisID ? dataset.yAxisID : 'yPrimary', // Assign y-axis for each dataset
    })),
  };

  const gridLineMode = styleFunctions.getColor(`grid-line-mode-${mode}`);
  const secondaryColorMode = styleFunctions.getColor(`secondary-color-mode-${mode}`);

  const options = {
    maintainAspectRatio: false, // Ensure chart fits the container by disabling aspect ratio
    responsive: true, // Makes the chart responsive
    scales: {
      x: {
        stacked: stackedX, // Enables stacking on the x-axis
        ticks: {
          color: secondaryColorMode,
          font: { size: 12 },
          minRotation: 30, // Set minimum rotation angle
          maxRotation: 45, // Set maximum rotation angle
        },
        title: {
          display: true,
          text: xAxisTitle,
          color: secondaryColorMode,
          font: { weight: 'bold' },
        },
      },
      yPrimary: {
        stacked: stackedY, // Enables stacking on the primary y-axis
        beginAtZero: true,
        ticks: {
          color: secondaryColorMode,
          font: { size: 12 },
          callback: function (value) {
            return `${primaryAxisLabelPrefix}${primaryAxisMultiplier*value}${primaryAxisLabelSuffix}`; // Add your desired suffix here
          },
        },
        title: {
          display: true,
          text: yAxisTitle,
          color: secondaryColorMode,
        },
      },
      ...(displaySecondaryAxis && {ySecondary: {
        position: 'right',
        beginAtZero: true,
        ticks: {
          color: secondaryColorMode,
          font: { size: 12 },
          callback: function (value) {
            return `${secondaryAxisLabelPrefix}${secondaryAxisMultiplier*value}${secondaryAxisLabelSuffix}`; // Add your desired suffix here
          },
        },
        title: {
          display: true,
          text: secondaryYAxisTitle,
          color: secondaryColorMode,
        },
        grid: {
          drawOnChartArea: false, // Prevent grid lines from overlapping
        },
      }}),
    },
    plugins: {
      legend: { display: showLegend },
      tooltip: { enabled: true },
    },
  };

  return (
    <div className="flex w-full h-[100%]">
      <Bar data={data} options={options} />
    </div>
  );
};

export default MultiSeriesBarChart;



