import React from 'react';
import { useSelector } from 'react-redux';
import Svg from '../../../components/Svg.js';
import * as styleFunctions from '../../../functions/styleFunctions.js';
import * as oomnielabsApi from '../../../apis/oomnielabsPythonApi.js'


const Topic = ({index, topic, currentTopic, setCurrentTopic, getChatHistory}) => {

   const mode = useSelector(state=>state.environment.mode)

   const handleDelete = async (selectedTopic)=>{
    const condition = `topic = '${selectedTopic}' RETURNING *`
    await oomnielabsApi.deleteRecord("chats", condition)
    getChatHistory()
   }


  return (
    <div 
        className={`${ currentTopic.length>0 && currentTopic === topic ? "chat-topic-clicked-mode-"+mode : "chat-topic-mode-"+mode} `} 
        key={index} 
        onClick={(e)=>{ e.stopPropagation(); setCurrentTopic(topic);}}
    >
        {topic}
        <div 
          className="flex w-auto h-auto justify-end" 
          onClick={(e)=>{e.stopPropagation(); handleDelete(topic);}}
        >
          <Svg
            iconName ="TrashIcon"
            height = "20px"
            width = "20px"
            fillColor = {styleFunctions.getColor(`icon-color-mode-${mode}`)}
            fillOpacity = "1"
          />
        </div>
    </div>
  )
}

export default Topic
