import React, { useState,useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import VoiceRecorder from '../../components/VoiceRecord.js';

import Navbar from './Navbar.js';
import * as oomnielabsApi from "../../apis/oomnielabs.js"
import MultiInput from '../../components/MultiInput.js';
import * as styleFunctions from '../../functions/styleFunctions.js'
import {toProperCase} from "../../functions/formatValue.js" 
import FloatingPanel from '../../components/FloatingPanel.js'
import Svg from '../../components/Svg.js'


import workflow_1 from "./workflow_1.json"
import workflow_2 from "./workflow_2.json"
import workflow_3 from "./workflow_3.json"
import object_1 from './object_1.json'
import integration_1 from './integration_1.json'


import workflow_1_response from './response_docs/workflow_1_response.js'
import workflow_2_response from './response_docs/workflow_2_response.js'
import workflow_3_response from './response_docs/workflow_3_response.js'
import object_1_response from './response_docs/object_1_response.js'
import integration_1_response from './response_docs/integration_1_response.js'
import Spinner from '../../components/Spinner.js';

const templateResponses = [
  {name: "workflow_1_response", type:"workflows", data: workflow_1_response},
  {name: "workflow_2_response", type:"workflows", data: workflow_2_response},
  {name: "workflow_3_response", type:"workflows", data: workflow_3_response},
  {name: "integration_1_response", type:"integrations", data: integration_1_response},
  {name: "object_1_response", type:"objects", data: object_1_response}
]


const documentTypes = [
  {
    type: "workflows", 
    prompt: `I work for a large enterprise company. We have a very specific change control process every time an Oomnitza system configuration change is made. I have just created a new workflow configuration in Oomnitza. I now have to create the documentation for the workflow for our change control process. 
    The first part of the document requires a summary in plain english that describes what the workflow does. This summary is usually less than 500 words but can be larger if the workflow is complex and needs more than 500 words for the summary - use your best judgement on the length of the summary to match the overall complexity of the workflow. 
    The second part should summarize all the specific changes where the change date is different than the created date - make certain the date information is listed in a human readable formant of Month Day Year HH:MM:SS. Include information on who made the change and what was changed (if available). 
    The third part of the document is a breakdown of each step of the workflow. Each step of the workflow should be listed separately with information about what that particular step of the workflow performs. 
    The fourth part of the document is a detailed technical section. In this section, be as specific as possible and include as many technical details as possible. Use table structures as needed for clarity and readability of the document. 
    Here is the configuration change I made:`,
    data: workflow_1
  },
  {
    type: "objects", 
    prompt: `I work for a large enterprise company. We have a very specific change control process every time an Oomnitza system configuration change is made. 
    I have just updated or created a table in Oomnitza. I now have to create the documentation for the table for our change control process.  
    Start by first creating a name for the document at the top which should be no more than 5 words describing the workflow.   
    The first part of the document requires a summary in plain english that describes what the table does. This summary is usually less than 400 words but can be larger if the table is complex and needs more than 400 words for the summary - use your best judgement on the length of the summary to match the overall complexity of the table. 
    The second part should summarize all the specific changes where the change date is different than the created date - make certain the date information is listed in a human readable formant of Month Day Year HH:MM:SS. 
    Include information on who made the change. 
    The third part of the document is a breakdown of the fields in the table and should include a count of the number of fields. Each field of the table should be listed separately with information about what that particular field of the table performs. Include the row # of the field. 
    The fourth part of the document is a detailed technical section. In this section, be as specific as possible and include as many technical details as possible for each field in the table. Include the row # of the field. If the field is a drop down list, provide all the values of the drop down. 
    Use tables in section 2, 3, and 4 for clarity and readability. 
    Note, please do not use the term JSON in the documentation. 
    Here is the Oomnitza table`,
    data: object_1
  },
  {
    type: "integrations", 
    prompt: `I work for a large enterprise company. We have a very specific change control process every time an Oomnitza system configuration change is made. I have just updated or created an integration in Oomnitza. 
    I now have to create the change control documentation for the integration for our change control process. Start by first creating a name for the document at the top which should be no more than 5 words describing the integration. 
    The first part of the document requires a summary in plain english that describes what the integration does. This summary is usually less than 400 words but can be larger if the integration is complex and needs more than 400 words for the summary - use your best judgement on the length of the summary to match the overall complexity of the integration.
    The second part should summarize all the specific changes where the change date is different than the created date - make certain the date information is listed in a human readable formant of Month Day Year HH:MM:SS. Include information on who made the change and what was changed (if available). 
    The third part of the document is a detailed technical section and must include a count of the number of keys before listing the keys. In this section, provide detailed technical specifications for each key, including the mapping data, transformation rules, expected values, data types, and dependencies for clarity. Do not exclude any keys. 
    Use tables in section 2 and 3 for clarity and readability.
    Note, please do not use the term JSON in the documentation.
    Here is the Oomnitza integration JSON.`,
    data: integration_1
  }
]

const context_data = [
  {type: "workflow", data: workflow_1},
  {type: "object", data: object_1}
]


const App = (props) => {

  const currentPage = useSelector(state=>state.oomnieWorkflowGenie.currentPage)

  const dispatch = useDispatch()
  const mode = useSelector(state=>state.environment.mode) 
  const [formData, setFormData] = useState({})
  const [generatedDoc, setGeneratedDoc] = useState(null)
  const [selectedCommonDocument, setSelectedCommonDocument] = useState(null)

  const [recordIconFileName, setRecordIconFileName] = useState("MicrophoneIcon")
  const [isRecording, setIsRecording] = useState(false)

  const [transcription, setTranscription] = useState(null)


    const handleChange = (e)=>{
        const {name, value} = e.target
        setFormData({...formData,...{[name]:value}})
    }
    
    const responseFormat = `
    Please format the response as an html document wrapped in a text string as follows:
      - contain everything within a div with a flex display, flex direction of column, full-width, padding of 20px, font size of 12px, font family of arial, and line-height of 1.5.
      - The name of the document should be contained in a <h2> tag with font size of 24px, black color, bold, bottom margin of 10px
      - Section headers should be within <h3> tags with font size of 20px black color, bold, and a top margin of 10px.
      - Sub headers should be within <h4> tags with font size of 16px gray color, and have a top-margin of 5px.
      - Body text should be within <p> tags with font size of 12px black color.
      - Tabular data should be within <table> tag with appropriate <thead> <tbody> <th> <tr> and <td>.  
      - Table width should fit within its parent div container.
      - Table even rows should have a background color of rgb(245,245,245) and font size of 10px, font color of black.
      - Table header should have a background color of white, font color of black, font weight should be bold and font size should be 10px.
      - Format dividing lines and borders to be 1px solid lightgray.
      - line-height for the whole document should be 1.5.
    `  
  

    const [showWaiting, setShowWaiting] = useState(false)

    const handleSubmit = async ()=>{

        setGeneratedDoc(null)

        setShowWaiting(true)

        let data = ["workflows", "integrations", "objects"]
        let prompt = `I have received a request to help generate a document as follows: ${formData.prompt}.
        which one of the following three catories does this request align to? ${data}. Please only response with
        the name of of one of the categories.
        `
        
        let response= await oomnielabsApi.pythonChatGPT(prompt, data)
        console.log(response)

        let selectedType = "workflows"
        if(response.includes("integrations")){
          selectedType = "integrations"
        }else if(response.includes("objects")){
          selectedType = "objects"
        }

        console.log(response)
        
        // let prompt = ""
        // if (selectedCommonDocument){
        //   prompt = `${commonPrompt}. ${responseFormat}`
        // }else{
        //   prompt = `${formData.prompt}. ${responseFormat}`
        // }
      
        // let response= await oomnielabsApi.pythonChatGPT(prompt, data)
        // response = (response.toString()).replace(/```/g,"").replace(/html/g,"")
        // console.log(response)
        // setGeneratedDoc(response)
      
        // if(response){
        //   setShowWaiting(false)
        // }

        const templateResponse = templateResponses.find(i=>i.type===selectedType).data
        setTimeout(()=>{
          setGeneratedDoc(templateResponse)
          setShowWaiting(false)
        },2000)
    }


    const [commonPrompt, setCommonPrompt] = useState(null)

    const handleDocTypeClick = async (item)=>{

      setGeneratedDoc(null)

      setShowWaiting(true)

      // console.log(item)
      setSelectedCommonDocument(item)
      let prompt = `${item.prompt}. ${responseFormat}`
      setCommonPrompt(prompt)
      // console.log("prompt",prompt)
      setFormData({...formData,...{"prompt":""},...{"data": item.data}})

      prompt = `${prompt}. ${responseFormat}`

      const data = item.data

      // let response= await oomnielabsApi.pythonChatGPT(prompt, data)
      // response = (response.toString()).replace(/```/g,"").replace(/html/g,"")
      // console.log(response)
      // setGeneratedDoc(response)
    
      // if(response){
      //   setShowWaiting(false)
      // }

      const templateResponse = templateResponses.find(i=>i.type===item.type).data
        setTimeout(()=>{
          setGeneratedDoc(templateResponse)
          setShowWaiting(false)
        },2000)

    }


  
    const handleDownload = async() => {
      console.log(generatedDoc)
      const response = await oomnielabsApi.generateDocx(generatedDoc)
      console.log(response)
    }

    const handleReset = ()=>{
      setFormData({prompt: "", data:""})
      setGeneratedDoc(null)
    }

    const handleRecord = async (e)=>{
      const filename = isRecording ? "MicrophoneIcon" : "StopRecordingIcon"
      setRecordIconFileName(filename)
      setIsRecording(!isRecording)

      if(transcription){
        console.log(transcription)
        setFormData({...formData,...{"prompt":transcription}})
      }
  }



  return (

    <div className={`flex flex-col w-full h-[100vh] items-center body-mode-${mode} overflow-y-auto transition duration-500`}>
  
      <div className={`flex flex-col h-auto w-full p-3`}>
        
        <div className={`flex flex-col w-full h-auto panel-mode-${mode} rounded-md p-3`}>

          <div className={`flex w-full justify-between h-auto items-center overflow-hidden transition duration-500`}>
            
            <div className={`flex flex-col w-full`}>
              
              <div className={`flex justify-between w-full items-center bg-mode-${mode} rounded-lg pe-3`}>
                <input
                  id="prompt" 
                  name="prompt"
                  label="Desribe the document you'd like to generate"
                  value = {formData.prompt}
                  onChange = {(e)=>handleChange(e)}
                  placeholder = "Describe the document you'd like to generate"
                  className={`flex w-full items-center outline-none text-[rgb(0,150,225)] bg-mode-${mode}  rounded-lg p-3`}
                />
                {recordIconFileName && 
                  <div onClick={(e)=>handleRecord(e)} title={isRecording ? "Stop Recording" : "Record"}>
                    <Svg
                      iconName ={recordIconFileName}
                      height = "30px"
                      width = "30px"
                      fillColor = {isRecording ? "red" : styleFunctions.getColor(`icon-color-mode-${mode}`)}
                      fillOpacity = "1"
                    />
                </div>
                }
              </div>

              {isRecording && 
                <div className={`flex w-full h-[25px] fade-in`}>
                  <VoiceRecorder 
                    isRecording={isRecording} 
                    setTranscription={setTranscription}
                  />
                </div>
              } 

            </div>

            <div className="flex p-3">
              <div onClick={(e)=>handleSubmit(e)} title="Generate">
                    <Svg
                      iconName ={"GenerateIcon"}
                      height = "30px"
                      width = "30px"
                      fillColor = {styleFunctions.getColor(`icon-color-mode-${mode}`)}
                      fillOpacity = "1"
                    />
                </div>

                <div onClick={()=>handleReset()} title="Reset">
                    <Svg
                      iconName ={"RefreshIcon"}
                      height = "30px"
                      width = "30px"
                      fillColor = {styleFunctions.getColor(`icon-color-mode-${mode}`)}
                      fillOpacity = "1"
                    />
                </div>
            </div>

          </div>

          <div className="flex flex-col w-full p-3">
            <div className={`flex w-full text-[14px]`} style={{color: styleFunctions.getColor(`secondary-color-mode-${mode}`)}}>Common Documents</div>
            <div className={`flex w-full mt-2`}>
              {documentTypes.map((item,index)=>(
                <div 
                  key={index}
                  id={item.type}
                  className={`flex button-mode-${selectedCommonDocument === item ? "primary" : "secondary"}-${mode} text-[12px] w-[150px] me-3`}
                  onClick = {()=>handleDocTypeClick(item)}
                >
                  {toProperCase(item.type)}
                </div>
                ))
              }

            </div>
          </div>

        </div>

        {generatedDoc && 

          <div className={`flex flex-col m-auto w-[800px] panel-mode-${mode} border-[1px] border-mode-${mode} overflow-y-auto p-1 justify-center items-center mt-5`}>
          
          <div className="flex w-full h-[50px] p-1 justify-between items-center border-b-[1px] border-gray-300">
            
            <div className="flex text-[24px] font-bold">
              Generated Document
            </div>
          
            <div className="flex mt-1">

              <div 
                onClick = {handleDownload}
                className={`flex hover:transform-[scale(1.1)] transition duration-500`}
                title="Download Document"
                >
                <Svg
                  iconName = "DownloadIcon"
                  height = "30px"
                  width = "30px"
                  fillColor = "gray"
                  fillOpacity = "1"
                  hoveredColor = "blue" 
                />
              </div>
            </div>
          </div>
          
          <div 
            className={`flex w-[612px] h-[1500px] p-3 overflow-y-auto bg-white mt-2`}>
            <div
              style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '20px'}}
              dangerouslySetInnerHTML={{ __html: generatedDoc }}
            />
          </div>

          </div>
        }

      </div>

      {showWaiting && 
        <div 
          className="absolute flex w-[100vw] h-[100vh] bg-[rgba(0,0,0,0.5)] mt-5"
          >
            <div
              style={{
                top: "50%",
                left: "50%",
                position: "fixed",
                display: "flex",
                height: "300px",
                width: "300px",
                transform: "translate(-50%, -50%)",
                cursor: "move",
                backgroundColor: "rgba(0,0,0,0)",
                zIndex: 99999,
              }}
              className="flex flex-col items-center justify-center"
            >
              <Spinner
              
              />

              <div className={`flex justify-center items-center text-[24px] primary-color-mode-${mode} mt-3`}>
                GenAI working...
              </div>

            </div>
            
        </div>
       }

    </div>
  )
}

export default App
