import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import * as oomnielabsApi from '../../apis/oomnielabs'
import Table from '../../components/Table'
import Svg from '../../components/Svg'

const Documents = () => {

  const mode = useSelector(state=>state.environment.mode)
  const [data, setData] = useState([])
  const [selectedRecords, setSelectedRecords] = useState([])
  const [selectedDocument, setSelectedDocument] = useState(null)
  const [generatedDoc, setGeneratedDoc] = useState(null)

  const getData = async()=>{
    const query = `SELECT 
      id as id,
      label as Name,
      type as Type,
      description as Description,
      record_created as "Record Created",
      last_update as "Last Update"
      FROM documents
      ORDER BY record_created desc
    `
    const response = await oomnielabsApi.getPythonData(query)
    setData(response)
  }

  useEffect(()=>{
    getData()
  },[])

  

  const handleRecordSelect = async (record)=>{
   
    setSelectedDocument(record)

    console.log(record)

    const query = `SELECT "contents" FROM documents WHERE id='${record.id}' LIMIT 1;`
    console.log(query)

    const response = await oomnielabsApi.getPythonData(query, "main")
    console.log(response)
    setGeneratedDoc(response[0].contents)
  }

  const handleCheck = (records)=>{
    console.log(records)
    setSelectedRecords(records)
  }

  const handleDownload = async() => {
    console.log(generatedDoc)
    const response = await oomnielabsApi.generateDocx(generatedDoc)
    console.log(response)
  }

  const hiddenColumns = ["id"]

  return (
    <div className={`flex flex-col w-full h-[100%]`}>
    
      { 
      data.length>0 && 
      <div 
        className={`flex w-full h-[400px]`}
        style={{border: `border-mode-${mode}`}}
      >
        <Table 
            data = {data}
            includeRowSelect = {true}
            includeEditRow = {true}
            formatHeader = {true}
            onCellClicked = {(record)=>handleRecordSelect(record)}
            onRowSelected = {(records)=>handleCheck(records)}
            hiddenColumns = {hiddenColumns}
            mode = {mode}
        />
      </div>
      }

      {selectedDocument &&
        <div className={`flex flex-col w-full mt-5 bg-gray-100`}>

          <div className={`flex flex-col m-auto w-[800px] h-[800px] panel-mode-${mode}`}></div>
          
          <div className="flex w-full h-[50px] justify-between items-center border-b-[1px] border-gray-300 p-3 mt-5">
            
            <div className="flex text-[24px] font-bold">Generated Document</div>
          
            <div className="flex mt-1">

              <div 
                onClick = {handleDownload}
                className={`flex hover:transform-[scale(1.1)] transition duration-500`}
                title="Download Document"
                >
                <Svg
                  iconName = "DownloadIcon"
                  height = "30px"
                  width = "30px"
                  fillColor = "gray"
                  fillOpacity = "1"
                  hoveredColor = "blue" 
                />
              </div>

            </div>
          </div>
          
          <div className={`flex flex-col w-[612px] h-[1500px] p-3 overflow-y-auto bg-white mt-2`}>
            <div
              style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '20px'}}
              dangerouslySetInnerHTML={{ __html: generatedDoc }}
            />
          </div>

        </div>
      }

    </div>
  )
}

export default Documents
