import React , {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';

import Generate from './Generate'
import Documents from './Documents'
import Navbar from './Navbar'


const App = () => {

  const mode = useSelector(state=>state.environment.mode)
  const currentPage = useSelector(state=>state.oomnieDocGenie.currentPage)
  const globalData = useSelector(state=>state.main.globalData)

  useEffect(()=>{
    console.log("globalData", globalData)
  },[])

  return (
    <div className={`flex flex-col w-full h-[100%] body-mode-${mode} items-center`}>

      <div className={`flex flex-col h-[3000px] w-full mt-5 p-3`}>

        <div className={`flex w-full h-[50px] text-[24px] title-mode-${mode}`}>Oomnie Doc Genie</div>

        <Navbar />

        {currentPage==="generate" && <Generate/>}
        {currentPage==="documents" && <Documents/>}

      </div>
    </div>
  )
}

export default App