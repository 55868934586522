import React from 'react';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify'; // Optional for sanitizing HTML strings

const FormatHTML = ({ text }) => {
  const mode = useSelector((state) => state.environment.mode);

  if (text && text.length > 0) {
    // Step 1: Replace double-escaped \\n with \n
    let cleanText = text.replace(/\\n/g, '\n');

    // Step 2: Replace **text** with <strong>text</strong>
    cleanText = cleanText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Step 3: Convert ```javascript ... ``` to <code>...</code>
    cleanText = cleanText.replace(
      /```javascript([\s\S]*?)```/g,      
        (match, codeBlock) => `<code>${codeBlock.trim()}</code>`
    );

    // Step 4: Detect Markdown table using regex
    const tableRegex = /\|.*\|\n\|[-| ]*\|\n([\s\S]*?)(?=\n\n|$)/;
    const tableMatch = cleanText.match(tableRegex);
    const tableMarkdown = tableMatch ? tableMatch[0] : null;
    const otherContent = tableMatch
      ? cleanText.replace(tableMarkdown, '').trim()
      : cleanText;

    // Step 5: Convert Markdown table to HTML
    const convertMarkdownTableToHTML = (markdown) => {
      const lines = markdown.trim().split('\n');
      const header = lines[0].split('|').map((col) => col.trim()).filter(Boolean);
      const rows = lines.slice(2).map((line) =>
        line.split('|').map((col) => col.trim()).filter(Boolean)
      );

      return (
        <table className={`border-mode-${mode} border-collapse w-full mt-5`}>
          <thead>
            <tr>
              {header.map((col, index) => (
                <th key={index} className={`border-mode-${mode} p-1 text-center font-bold`}>
                  {col.replace(/<\/?strong>/g, '')}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((col, colIndex) => (
                  <td key={colIndex} className={`border-mode-${mode} p-1`}>
                    {col.replace(/<\/?strong>/g, '')}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    };

    // Step 6: Process remaining text into HTML
    const processTextToHTML = (content) => {
      return content
        .split('\n')
        .map((line) => (line.trim() ? `<p>${line}</p>` : '<br />')) // Wrap each line in <p> or <br />
        .join('');
    };

    // Process and sanitize content
    const sanitizedOtherContent = DOMPurify.sanitize(processTextToHTML(otherContent));

    return (
      <div>
        {/* Render other content */}
        <div
          dangerouslySetInnerHTML={{ __html: sanitizedOtherContent }}
        />
        {/* Render table if present */}
        {tableMarkdown && (
          <div>
            {convertMarkdownTableToHTML(tableMarkdown)}
          </div>
        )}
      </div>
    );
  }

  return null; // Handle empty or undefined text
};

export default FormatHTML;
