export const flowLoad = [
    {
      "labels": "Sep 1",
      "values": 30,
      "trend": 29.199999999999992,
      "average": 22,
      "moving_average": 30
    },
    {
      "labels": "Oct 1",
      "values": 26,
      "trend": 25.599999999999994,
      "average": 22,
      "moving_average": 28
    },
    {
      "labels": "Nov 1",
      "values": 19,
      "trend": 21.999999999999996,
      "average": 22,
      "moving_average": 25
    },
    {
      "labels": "Dec 1",
      "values": 20,
      "trend": 18.4,
      "average": 22,
      "moving_average": 23.75
    },
    {
      "labels": "Jan 1",
      "values": 15,
      "trend": 14.8,
      "average": 22,
      "moving_average": 22
    }
  ]


  export const flowEfficiency = [
    {
      "labels": "Sep 1",
      "values": 0.44,
      "trend": 0.424,
      "average": 0.248,
      "moving_average": 0.44
    },
    {
      "labels": "Oct 1",
      "values": 0.4,
      "trend": 0.336,
      "average": 0.248,
      "moving_average": 0.42
    },
    {
      "labels": "Nov 1",
      "values": 0.14,
      "trend": 0.248,
      "average": 0.248,
      "moving_average": 0.327
    },
    {
      "labels": "Dec 1",
      "values": 0.12,
      "trend": 0.16,
      "average": 0.248,
      "moving_average": 0.275
    },
    {
      "labels": "Jan 1",
      "values": 0.14,
      "trend": 0.072,
      "average": 0.248,
      "moving_average": 0.248
    }
  ]

  export const flowVelocity = [
    {
      "labels": "Sep 1",
      "values": 9,
      "trend": 6.6,
      "average": 8.8,
      "moving_average": 9
    },
    {
      "labels": "Oct 1",
      "values": 6,
      "trend": 7.7,
      "average": 8.8,
      "moving_average": 7.5
    },
    {
      "labels": "Nov 1",
      "values": 8,
      "trend": 8.8,
      "average": 8.8,
      "moving_average": 7.7
    },
    {
      "labels": "Dec 1",
      "values": 7,
      "trend": 9.9,
      "average": 8.8,
      "moving_average": 7.5
    },
    {
      "labels": "Jan 1",
      "values": 14,
      "trend": 11,
      "average": 8.8,
      "moving_average": 8.8
    }
  ]



  export const flowTime = [
    {
      "labels": "Sep 1",
      "values": 18,
      "trend": 22.6,
      "average": 25.4,
      "moving_average": 18
    },
    {
      "labels": "Oct 1",
      "values": 28,
      "trend": 24,
      "average": 25.4,
      "moving_average": 23
    },
    {
      "labels": "Nov 1",
      "values": 30,
      "trend": 25.4,
      "average": 25.4,
      "moving_average": 25.3
    },
    {
      "labels": "Dec 1",
      "values": 24,
      "trend": 26.8,
      "average": 25.4,
      "moving_average": 25
    },
    {
      "labels": "Jan 1",
      "values": 27,
      "trend": 28.2,
      "average": 25.4,
      "moving_average": 25.4
    }
  ]


  export const flowPredictability = [
    {
      "labels": "Sep 1",
      "90_pct": 0.48,
      "80_pct": 0.35,
      "70_pct": 0.3
    },
    {
      "labels": "Oct 1",
      "90_pct": 0.42,
      "80_pct": 0.27,
      "70_pct": 0.25
    },
    {
      "labels": "Nov 1",
      "90_pct": 0.12,
      "80_pct": 0.12,
      "70_pct": 0.09
    },
    {
      "labels": "Dec 1",
      "90_pct": 0.43,
      "80_pct": 0.42,
      "70_pct": 0.42
    },
    {
      "labels": "Jan 1",
      "90_pct": 0.25,
      "80_pct": 0.22,
      "70_pct": 0.22
    }
  ]


  export const flowDistribution = [
    {
      "labels": "Features",
      "values": 14,
      "pct_of_total": 0.64
    },
    {
      "labels": "Defects",
      "values": 5,
      "pct_of_total": 0.23
    },
    {
      "labels": "Debt",
      "values": 2,
      "pct_of_total": 0.09
    },
    {
      "labels": "Risk",
      "values": 1,
      "pct_of_total": 0.05
    }
  ]